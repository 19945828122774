<script setup lang="ts">
import { getEdgeClasses } from '~/lib/getEdgeClasses'

import {
  ITEMS_PER_ROW_LARGE_GRID,
  ITEMS_PER_ROW_SMALL_GRID,
} from '~/configuration/global.configuration'
import type {
  ProductListBlockContent,
} from '~/types/storyblok/product-list-block'
import {
  normalizeProductListBlock,
} from '~/types/storyblok/product-list-block'
import type { Product } from '~/types/models/product'
import { productUrl } from '~/lib/routing'
import type { trackBlockClickEmit } from '~/types/track-block-click'
import type { AddToCartSources } from '~/types/gtm'

interface Props {
  blok: ProductListBlockContent
  products?: Product[]
  pageSource?: AddToCartSources
}

const props = withDefaults(defineProps<Props>(), {
  pageSource: 'otherPage',
})
const emit = defineEmits<trackBlockClickEmit>()

const storyblokStore = useStoryblokStore()

const data = normalizeProductListBlock(props.blok, storyblokStore.state.categories)
const amountOfItemsPerRow = data.gridType === 'product-grid-small'
  ? ITEMS_PER_ROW_SMALL_GRID
  : ITEMS_PER_ROW_LARGE_GRID

const amountOfProductsToShow = computed(() => amountOfItemsPerRow * data.gridRows)

const products = computed(() => props.products?.slice(0, amountOfProductsToShow.value))
</script>

<template>
  <div v-if="blok" v-editable="blok" class="container" :class="[getEdgeClasses(data.edgeStyle)]">
    <h2 v-if="data.title" class="title">
      {{ data.title }}
    </h2>
    <div
      class="grid" :class="[
        { 'grid--small': data.gridType === 'product-grid-small' },
        { 'grid--large': data.gridType === 'product-grid-large' },
      ]"
    >
      <ProductCard
        v-for="product in products"
        :key="product.sku"
        :product="product"
        :has-quick-shop="true"
        :grid-type="data.gridType"
        :page-source="props.pageSource"
        @product-click="p => emit('trackBlockClick', productUrl(p.sku), 'product')"
      />
    </div>
    <ButtonIcon
      v-if="data.link.id && data.link.cached_url"
      class="btn"
      :text="$t(data.label)"
      left-icon="bullet"
      right-icon="forward"
      :blinking="true"
      :background-color="data.ctaBackgroundColor"
      :text-color="data.ctaTextColor"
      data-test="cta-internal-link-btn"
      tag="nuxt-link"
      :target="data.link.cached_url.startsWith('http') ? '_blank' : '_self'"
      :to="$url(data.link.cached_url)"
      @click="() => emit('trackBlockClick', $url(data.link.cached_url), 'button')"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/headings';
@import 'assets/scss/rules/edges';
.container {
  background: white;
}
.grid {
  display: grid;
  border-top: 1px solid var(--gray-light);
  border-bottom: 1px solid var(--gray-light);
  background-color: var(--gray-light);

  &--small {
    background-color: var(--white);
    grid-template-columns: repeat(3, 1fr);
    @media (min-width: $tablet) {
      grid-template-columns: repeat(8, 1fr);
    }
  }
  &--large {
    grid-gap: 1px;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: $tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.title {
  padding: 1.6rem;
  @include h6;
  @media (min-width: $tablet) {
    padding: 2.4rem;
    @include h5;
  }
}

.btn {
  margin: 4.8rem 2.4rem;
}
.teeth {
  @include teeth;
  margin-top: -1.2rem;
}

.scallop {
  @include scallop;
  margin-top: -1.2rem;
}

.wave {
  @include wave;
  margin-top: -1.2rem;
}
</style>
